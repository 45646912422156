body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Colors {
  /* Default Color Scheme */
/* Primary Colors: Organic */
--Dark: #001d56;
--Light: #c8daff;
--Accent: #0055fe;
}

.noOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.centerText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}