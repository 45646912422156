.App {
  text-align: center;
}

.App-Section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--Light);
  background-color: var(--Dark);
}

.App-Grid {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
}

.App-Flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  gap: 50px;

}

.App-Demo {
  width: 600px;
  height: 80vh;
  border-radius: 15px;
}

.App-Demo-Tall {
  width: 100%;
  height: 80vh;
  background-color: white;
  border-radius: 15px;
}

.App-Demo-Short{
  width: 100%;
  height: 25vh;
  background-color: white;
  border-radius: 15px;
  margin-bottom: 20px;
}

.App-Demo-Section {
  height: 30%;
}

.App-Demo-Header {
  background-color: lightgrey;
  height: 50px;
  border-radius: 15px 15px 0 0;

}

.App-Demo-Header-Button {
  position: absolute;
  background-color: red;
  height: 20px;
  width: 20px;
  margin: 10px;
  padding: 5px;
  border-radius: 50px;
}

.App-Demo-Header-Button-X {
  position: absolute;
  color: var(--Dark);
  height: 20px;
  width: 20px;
  margin: 12px 15px;
  padding: 0;
  font-size: large;
}

.App-Demo-Header-URL {
  position: absolute;
  color: var(--Dark);
  margin: 0;
  width: 600px;
  text-align: center;
  
}

.App-Demo-Page {
  width: 100%;
  padding-top: 50px;
}

.App-Demo-Page-Line {
  height: 50px;
  width: 100%;
  
}

.App-Demo-Page-Line-Title {
  width: 30%;
  margin: 10px;
  display: inline-block;
  color: var(--Dark);
}

.App-Demo-Page-Line-Input {
  width: 50%;
  margin: 0;
  padding: 5px;
  display: inline-block;
  border-radius: 5px;

}

.App-Demo-Page-Line-Header{
  width: 580px;
  margin: 10px;
  display: inline-block;
  color: var(--Dark);
}

.App-Demo-Page-Line-Tag {
  width: 580px;
  margin: 10px;
  display: inline-block;
  color: var(--Dark);
  flex-wrap: wrap;
}